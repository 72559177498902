<template>
  <div class="donators-list-wrapper">
    <Back
      v-if="globalSettings"
      color="#0062ae"
      :label="globalSettings['button_names']['back_button']"
      :customBack="true"
      @back="back"
    />
    <div class="donators-list">
      <div class="donators-list__table">
        <div class="donators-list__table-head">
          <div class="donators-list__table-filters">
            <div class="donators-list__table-search">
              <form @submit.prevent="search">
                <div
                  class="donators-list__table-search-input"
                  @click="clickOnSearch"
                >
                  <InputText
                    placeholder="Suchbegriff"
                    v-model="searchValue"
                    component-name="DonatorsList"
                    @on-key-press="onKeyPress"
                  />
                  <SearchIcon v-if="!searchValue" />
                  <CloseIcon v-else @click="clearSearch" />
                </div>

                <!-- <button type="submit">Suchen</button>-->
              </form>
            </div>
            <div class="donators-list__table-letters">
              <div
                v-for="letter in alphabet"
                :key="letter"
                class="donators-list__table-letters-item"
                :class="{ active: letter === filterLetter }"
                @click="filterByLetter(letter)"
              >
                {{ letter }}
              </div>
            </div>
          </div>
          <div class="donators-list__table-head-row">
            <div
              class="donators-list__table-th donators-list__table-th-anchor anchor-letter"
            >
              {{ this.currentLetter }}
            </div>
            <div class="donators-list__table-th"><span>Unternehmen</span></div>
            <div class="donators-list__table-th"><span>Spender</span></div>
            <div class="donators-list__table-th">
              <span>Art der Helfer</span>
            </div>
            <div class="donators-list__table-th"><span>Ort, Land</span></div>
          </div>
        </div>
        <div v-if="donatorsList.list.length" class="donators-list__table-body">
          <div
            class="donators-list__table-row"
            v-for="item in donatorsList.list"
            :key="item.id"
          >
            <div
              class="donators-list__table-td donators-list__table-td-anchor anchor-letter"
            >
              <span v-if="donatorsList.donatorsListLetters[item.id]">{{
                donatorsList.donatorsListLetters[item.id]
              }}</span>
            </div>
            <div class="donators-list__table-td">{{ item.name }}</div>
            <div class="donators-list__table-td">{{ item.vname }}</div>
            <div class="donators-list__table-td">{{ item.type }}</div>
            <div class="donators-list__table-td">
              {{ item.land }} {{ item.ort }}
            </div>
          </div>
        </div>
        <div class="donators-list__table-footer">
          <template v-if="Number(donatorsList.itemsCount) > 0">
            <span class="counter">
              {{ donatorsList.loadedItems }}/{{ donatorsList.itemsCount }}
            </span>
            <div class="label">werden angezeigt</div>
          </template>
          <div v-else class="label">{{ globalSettings.not_found_text }}</div>
          <div v-if="donatorsList.donatorsListLoading" class="loader-wrapper">
            <div class="loader"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/icons/SearchIcon.vue';
import CloseIcon from '@/icons/CloseIcon.vue';
import Back from '@/components/Back/Back.vue';
import InputText from '@/components/InputText/InputText.vue';

export default {
  name: 'DonatorsList',
  components: {
    InputText,
    Back,
    CloseIcon,
    SearchIcon,
  },
  data() {
    return {
      container: null,
      filterLetter: '',
      searchValue: '',
      existLetters: {},
      currentLetter: '',
      alphabet: [
        'Alle',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
    };
  },
  watch: {
    'donatorsList.list': {
      handler() {
        this.$nextTick(() => {
          this.detectCurrentLetter();
        });
      },
      deep: true,
    },
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    donatorsList() {
      return this.$store.state.donatorsList;
    },
  },
  mounted() {
    this.container = document.querySelector('.app-container__content');
    this.fetchList();

    this.container.addEventListener('scroll', this.scrollContent);
  },
  beforeUnmount() {
    this.container.removeEventListener('scroll', this.scrollContent);
  },
  methods: {
    clickOnSearch(e) {
      if (e.target.querySelector('input')) {
        e.target.querySelector('input').focus();
      }
    },
    onKeyPress(button) {
      if (button === '{enter}') {
        this.search();
      }
      if (button === '{reset}') {
        this.searchValue = '';
        this.filterLetter = '';
        this.$store.commit('donatorsList/clearDonatorsList');
        this.fetchList();
      }
    },
    scrollContent(event) {
      const element = event.target;
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.fetchList(this.searchValuec);
      }

      this.detectCurrentLetter();
    },
    detectCurrentLetter() {
      const element = this.container;
      const head = element.querySelector('.donators-list__table-head');
      const headRect = head.getBoundingClientRect();
      const bottomHead = headRect.top + headRect.height;

      const anchors = element.querySelectorAll(
        '.donators-list__table-td-anchor span'
      );

      anchors.forEach((el) => {
        const elRect = el.getBoundingClientRect();
        const elBottom = elRect.top + elRect.height;
        if (bottomHead >= elBottom && el.textContent !== this.currentLetter) {
          this.currentLetter = el.textContent;
        }
      });
    },
    fetchList() {
      this.currentLetter = '';
      if (
        this.donatorsList.donatorsListPages !== null &&
        this.donatorsList.donatorsListPaged >=
          this.donatorsList.donatorsListPages
      )
        return;

      this.$store.dispatch('donatorsList/fetchDonatorsList', {
        paged: this.donatorsList.donatorsListPaged,
        search: this.searchValue,
        letter: this.filterLetter,
      });
      this.$store.commit(
        'donatorsList/setDonatorsListPaged',
        this.donatorsList.donatorsListPaged + 1
      );
    },
    search() {
      this.container.scrollTop = 0;
      this.$store.commit('donatorsList/clearDonatorsList');
      this.fetchList();
    },
    clearSearch() {
      this.searchValue = '';
      this.$store.commit('donatorsList/clearDonatorsList');
      this.fetchList();
    },
    filterByLetter(letter) {
      if (letter === 'Alle' || this.filterLetter === letter) {
        this.filterLetter = '';
      } else {
        this.filterLetter = letter;
      }

      this.$store.commit('donatorsList/clearDonatorsList');
      this.fetchList();
    },
    back() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped lang="scss">
.donators-list {
  margin-bottom: 50px;
  position: relative;
  padding: 60px 0;

  &-wrapper {
    margin-top: 50px;
  }

  &:after {
    position: absolute;
    content: '';
    background-color: #ebebeb;
    width: 300vw;
    height: 100%;
    left: -100vw;
    top: 0;
  }

  &__table {
    width: 100%;
    border: 1px solid #444444;
    padding: 25px;
    border-radius: 25px;
    position: relative;
    z-index: 2;
    background-color: #fff;

    &-letters {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      max-width: 740px;
      margin: 0 auto 30px;

      &-item {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #c89c66;
        border-radius: 1px;
        font-weight: 700;
        color: #c89c66;
        cursor: pointer;

        &.active {
          color: #fff;
          background-color: #c89c66;
        }
      }
    }
    &-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #c89c66;
      margin: 50px auto;
      gap: 10px;
      font-weight: 700;
    }

    &-body {
      padding: 0 120px;
      box-sizing: border-box;
    }

    &-head {
      position: sticky;
      top: 0;
      background-color: #fff;
      box-shadow: 0px 8px 10px 0px #343a401a;
      z-index: 2;
      width: 100%;
      box-shadow: 0px 8px 10px 0px #343a401a;
      padding: 20px 120px;
      box-sizing: border-box;
      &-row {
        display: flex;
        width: 100%;
        position: relative;
      }
    }

    &-row {
      border-bottom: 1px solid #c4c4c4;
      display: flex;
      width: 100%;
      position: relative;
    }

    &-th {
      color: #c89c66;
      font-weight: 700;
      text-align: center;
      border-right: 1px solid #c89c66;
    }

    &-th,
    &-td {
      width: 100%;
      padding: 20px 5px;

      &:nth-child(1) {
        width: 120px;
        position: absolute;
        right: 100%;
        //border: none;
      }
    }

    .anchor-letter {
      color: #c89c66;
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-search {
      padding-bottom: 30px;
      border-bottom: 1px solid #eaeaea;
      margin: 0 auto 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      form {
        display: flex;
      }

      button {
        text-decoration: underline;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        margin-left: 20px;
        cursor: pointer;
        font-weight: 700;
      }

      &-input {
        position: relative;

        .input-text {
          padding-bottom: 0;
        }
        :deep(input) {
          border-radius: 0;
          padding: 0 5px;
          height: 25px;
          width: 370px;
          outline: none;
          z-index: 1;
          padding-right: 20px;
          border: none;
          border-bottom: 1px solid #dedede;
          box-shadow: none;

          &::placeholder {
            color: #bdbdbd;
          }

          &:focus::placeholder {
            opacity: 0;
          }
        }

        svg {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 15px;
          height: 15px;
          cursor: pointer;
        }
      }
    }
  }
  .loader-wrapper {
    height: 20px;
  }
  .loader {
    width: 10px;
    height: 20px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 20px;
    margin-top: 10px;
    position: relative;
    background: currentColor;
    color: #b98d57;
    box-sizing: border-box;
    animation: animloader 0.3s 0.3s linear infinite alternate;
  }
  .loader::after,
  .loader::before {
    content: '';
    box-sizing: border-box;
    width: 10px;
    height: 20px;
    border-radius: 4px;
    background: currentColor;
    position: absolute;
    bottom: 0;
    left: 15px;
    animation: animloader1 0.3s 0.45s linear infinite alternate;
  }
  .loader::before {
    left: -15px;
    animation-delay: 0s;
  }

  @keyframes animloader {
    0% {
      height: 20px;
      transform: translateY(0);
    }
    100% {
      height: 10px;
      transform: translateY(10px);
    }
  }

  @keyframes animloader1 {
    0% {
      height: 28px;
    }
    100% {
      height: 4.8px;
    }
  }
}
</style>
