<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.28835 11.9808C12.017 10.8211 13.2889 7.66911 12.1293 4.94052C10.9696 2.21193 7.81753 0.940035 5.08887 2.09966C2.36021 3.25928 1.08828 6.4113 2.24793 9.13989C3.40759 11.8685 6.55969 13.1404 9.28835 11.9808Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9841 10.8359L15.1477 15"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup></script>

<style scoped lang="scss"></style>
