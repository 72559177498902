<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.6301 27.4282L2.57178 0.369873L0.369846 2.5718L27.4282 29.6301L29.6301 27.4282Z"
      fill="black"
    />
    <path
      d="M0.369914 27.4282L27.4282 0.369873L29.6302 2.5718L2.57185 29.6301L0.369914 27.4282Z"
      fill="black"
    />
  </svg>
</template>

<script setup></script>

<style scoped lang="scss"></style>
